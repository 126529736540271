<template>
  <main>
    <div class="container">
      <h1>Статистика заказов</h1>
      <ThisComponent></ThisComponent>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminOrdersStatistics'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-order-statistics')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style scoped>
  .container {
    background: #ffffff;
    padding-bottom: 20px;
    padding-top: 5px;
  }
</style>
