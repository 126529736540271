<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8 col-xl-7">
          <h1>Загрузка транзакций</h1>
          <ThisComponent></ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminTransactionsUpload'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-transactions-upload')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style scoped>
  .container {
    background: #ffffff;
    padding-bottom: 20px;
    padding-top: 5px;
  }
</style>
