<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8 col-xl-7">
          <ThisComponent></ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminUserEdit'

ThisComponent.mounted = async function () {
  this.$store.commit('app/updatePageClass', 'page--admin-user-edit')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  },
  data: () => ({
  })
}
</script>

<style scoped>
  .container {
    background: #ffffff;
    padding-bottom: 20px;
    padding-top: 5px;
  }
</style>
